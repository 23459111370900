import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import './App.css';
import Customers from './Customers';
import CustomerDetails from './CustomerDetails';
import AdditionalContent from './AdditionalContent';
import Footer from './Footer';
import HeaderPages from './HeaderPages';  // Import the HeaderPages component
import JavaGuide from './programming-guide/guide-js/JavaGuide';
import PythonGuide from './programming-guide/guide-js/PythonGuide';
import PhpGuide from './programming-guide/guide-js/PhpGuide';
import JavaScriptGuide from './programming-guide/guide-js/JavaScriptGuide';
import CppGuide from './programming-guide/guide-js/CppGuide';
import CppInterview from './interview-questions/questions-js/CppInterview';
import JavaInterview from './interview-questions/questions-js/JavaInterview';
import JavaScriptInterview from './interview-questions/questions-js/JavaScriptInterview';
import PhpInterview from './interview-questions/questions-js/PhpInterview';
import PythonInterview from './interview-questions/questions-js/PythonInterview';
import CppProjects from './build-projects/projects-js/CppProjects';
import PhpProjects from './build-projects/projects-js/PhpProjects';
import PythonProjects from './build-projects/projects-js/PythonProjects';
import JavaProjects from './build-projects/projects-js/JavaProjects';
import JavaScriptProjects from './build-projects/projects-js/JavaScriptProjects';





class App extends Component {
  render() {
    return (
      <div className="App">
        <HeaderPages /> {/* Use the HeaderPages component */}
        <Route
          path="/"
          render={({ location }) => (
            location.pathname !== '/java-guide' &&
            location.pathname !== '/python-guide' &&
            location.pathname !== '/php-guide' &&
            location.pathname !== '/javascript-guide' &&
            location.pathname !== '/cpp-guide' &&
            location.pathname !== '/java-interview' &&
            location.pathname !== '/python-interview' &&
            location.pathname !== '/javascript-interview' &&
            location.pathname !== '/cpp-interview' &&
            location.pathname !== '/php-interview' && 
            location.pathname !== '/cpp-projects' &&
            location.pathname !== '/php-projects' &&
            location.pathname !== '/python-projects' &&
            location.pathname !== '/java-projects' &&
            location.pathname !== '/javascript-projects' &&(
              <AdditionalContent />
            )
          )}
        />

        <Switch>
          <Route exact path="/" render={() => (
            <Redirect to="/Home" />
          )} />
          <Route exact path='/Home' component={Customers} />
          <Route path='/customerdetails/:id' component={CustomerDetails} />
          <Route path='/java-guide' component={JavaGuide} />
          <Route path='/python-guide' component={PythonGuide} />
          <Route path='/php-guide' component={PhpGuide} />
          <Route path='/javascript-guide' component={JavaScriptGuide} />
          <Route path='/cpp-guide' component={CppGuide} />
          <Route path='/java-interview' component={JavaInterview} />
          <Route path='/python-interview' component={PythonInterview} />
          <Route path='/javascript-interview' component={JavaScriptInterview} />
          <Route path='/cpp-interview' component={CppInterview} />
          <Route path='/php-interview' component={PhpInterview} />
          <Route path='/cpp-projects' component={CppProjects} />
          <Route path='/php-projects' component={PhpProjects} />
          <Route path='/python-projects' component={PythonProjects} />
          <Route path='/java-projects' component={JavaProjects} />
          <Route path='/javascript-projects' component={JavaScriptProjects} />
        </Switch>

        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
