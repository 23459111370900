// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";

// Your Firebase configuration (find this in your Firebase project settings)
const firebaseConfig = {
    apiKey: "AIzaSyDrMU91j2YyjpCJLlW5Y0pNKTUtzPDw-6o",
    authDomain: "crunch-coding.firebaseapp.com",
    projectId: "crunch-coding",
    storageBucket: "crunch-coding.appspot.com",
    messagingSenderId: "768108292790",
    appId: "1:768108292790:web:b1f4786bbc7951f9595417"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
