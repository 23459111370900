import React, { useState } from "react";
import data from '../questions-json/JavaScriptInterview.json';
import '../questions-css/JavaScriptInterview.css';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrowNightBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { FaJs, FaListAlt, FaBars } from 'react-icons/fa';

const JavaScriptInterview = () => {
  const [selectedSubtopic, setSelectedSubtopic] = useState(data.topics[0].subtopics[0].title);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSubtopicClick = (subtopic) => {
    setSelectedSubtopic(subtopic.title);
    setSidebarOpen(false);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const renderSteps = () => {
    const subtopic = data.topics.flatMap(topic =>
      topic.subtopics.flatMap(subtopic => subtopic.subtopics || subtopic)
    ).find(subtopic => subtopic.title === selectedSubtopic);

    if (!subtopic) return null;

    return subtopic.steps.map((step, stepIndex) => (
      <div key={stepIndex} className="step-jsi">
        <h3 className="step-subject-jsi">{step.subject}</h3>
        <h4 className="step-question-jsi">{step.question}</h4>
        <div className="step-solution-jsi">
          {step.solution.split('\n').map((line, index) => (
            <h5 key={index}>{line}</h5>
          ))}
        </div>
        <SyntaxHighlighter language="javascript" style={tomorrowNightBlue} className="code-block">
          {step.program}
        </SyntaxHighlighter>
      </div>
    ));
  };

  return (
    <div className="javascript-interview">
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        <FaBars /> JavaScript
      </button>
      <div className={`sidebar-jsi ${sidebarOpen ? 'open' : ''}`}>
        {data.topics.map((topic, index) => (
          <div key={index}>
            <h2 className="topic-title-jsi"><FaListAlt /> {topic.title}</h2>
            <ul>
              {(topic.subtopics || []).map((subtopic, subIndex) => (
                <React.Fragment key={subIndex}>
                  <li onClick={() => handleSubtopicClick(subtopic)} className="subtopic-title-jsi">
                    <FaJs /> {subtopic.title}
                  </li>
                  {(subtopic.subtopics || []).map((nestedSubtopic, nestedIndex) => (
                    <li
                      key={`${subIndex}-${nestedIndex}`}
                      onClick={() => handleSubtopicClick(nestedSubtopic)}
                      className="subtopic-title nested-subtopic"
                    >
                      <FaJs /> {nestedSubtopic.title}
                    </li>
                  ))}
                </React.Fragment>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="content-jsi">
        {selectedSubtopic ? (
          <React.Fragment>
            <h1 className="selected-subtopic-title-jsi"><FaJs /> {selectedSubtopic}</h1>
            <div className="steps-jsi">
              {renderSteps()}
            </div>
          </React.Fragment>
        ) : (
          <p>Please select a subtopic to view the content.</p>
        )}
      </div>

      <div className="ads-sidebar-jsi">
        {/* Place your ads or additional sidebar content here */}
       
      </div>
    </div>
  );
};

export default JavaScriptInterview;
