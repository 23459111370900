import React, { Component } from 'react';
import { FaRegCalendarAlt, FaCode } from 'react-icons/fa';
import { MdLightbulb } from 'react-icons/md';
import { FaListAlt, FaStickyNote, FaEllipsisH } from 'react-icons/fa';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-php';
import 'ace-builds/src-noconflict/theme-twilight';
import 'ace-builds/webpack-resolver';
import axios from 'axios';
import './CustomerDetails.css';



export default class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerDetails: null,
      error: null,
      code: '',
      language: 'java',
      copied: false,
    };
  }

  componentDidMount() {
    this.getCustomerDetails(this.props.val);
    // Register the clearCodeEditor function
    this.props.setClearCodeEditor(this.clearCodeEditor);
  }

  componentDidUpdate(prevProps) {
    if (this.props.val !== prevProps.val) {
      this.getCustomerDetails(this.props.val);
    }
  }

  clearCodeEditor = () => {
    this.setState({ code: '' });
  };
  getCurrentDateKey() {
    const today = new Date();
    return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
  }

  getStoredChallenge(id) {
    const dateKey = this.getCurrentDateKey();
    const storedData = JSON.parse(localStorage.getItem(`customer${id}`));
    if (storedData && storedData.date === dateKey) {
      return storedData.challenge;
    }
    return null;
  }

  storeChallenge(id, challenge) {
    const dateKey = this.getCurrentDateKey();
    const dataToStore = { date: dateKey, challenge };
    localStorage.setItem(`customer${id}`, JSON.stringify(dataToStore));
  }

  async getCustomerDetails(id) {
    const storedChallenge = this.getStoredChallenge(id);

    if (storedChallenge) {
      this.setState({ customerDetails: storedChallenge, error: null });
    } else {
      try {
        const response = await axios.get(`assets/samplejson/customer${id}.json`);
        const challenges = response.data;
        if (challenges.length > 0) {
          const randomIndex = Math.floor(Math.random() * challenges.length);
          const randomChallenge = challenges[randomIndex];
          this.storeChallenge(id, randomChallenge);
          this.setState({ customerDetails: randomChallenge, error: null });
        } else {
          this.setState({ error: 'No challenges found.' });
        }
      } catch (error) {
        this.setState({ error: 'Failed to fetch data.' });
      }
    }
  }

  handleCodeChange = (newCode) => {
    this.setState({ code: newCode });
  }

  handleLanguageChange = (event) => {
    this.setState({ language: event.target.value });
  }

  handleRunCode = () => {
    console.log("Running code:", this.state.code);
  }

  handleButtonClick = () => {
    this.setState({ copied: true });
  }

  // Update handleViewCode to set the code state
  handleViewCode = (code) => {
    this.setState({ code });
  }

  render() {
    const { customerDetails, error, code, language } = this.state;

    if (error) return <p>{error}</p>;
    if (!customerDetails) return <p>Loading Data...</p>;

    const currentDateKey = this.getCurrentDateKey();

    return (
      <div className="customerdetails">
      <h3 className="customer-name">{customerDetails.name}</h3>
      <p className="current-date"><FaRegCalendarAlt className="icon"/> Current Date: {currentDateKey}</p>
     
      
      <div className="content-container">
        <div className="fake-code-editor">
          <div className="fake-code-editor-header">
            <div className="dot yellow"></div>
            <div className="dot green"></div>
            <div className="dot red"></div>
          </div>
          <div className="fake-code-editor-content">
            <p><FaCode className="icon"/> Programming Language: {customerDetails.name}</p>
            <h2 className="quiz-challenge">
  <MdLightbulb className="icon" /> Quiz Challenge: {customerDetails.quizname}
</h2>
            <h3 className="quiz">Challenge: {customerDetails.quiz}</h3>
          
            <h4 className="example">
  <FaListAlt style={{ marginRight: '8px' }} />
  Examples:<br />
  {customerDetails.examp.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))}
  <div className="decorative-line">
    <FaEllipsisH />
  </div>
</h4>

<h4 className="notes">
  <FaStickyNote style={{ marginRight: '8px' }} />
  Notes:<br />
  {customerDetails.note.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))}
  <div className="decorative-line">
    <FaEllipsisH />
  </div>
</h4>
            <p>
              <button className="view-code-button" onClick={() => this.handleViewCode(customerDetails.viewcode)}>
                Solution
              </button>
            </p>
          </div>
        </div>
        
        <div className="code-editor">
          <AceEditor
            mode={language}
            theme="twilight"
            name="codeEditor"
            onChange={this.handleCodeChange}
            fontSize={14}
            width="100%"
            height="500px"
            value={code}
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
            }}
          />
          
           {/* 
  <CopyToClipboard text={code} onCopy={this.handleButtonClick}>
    <button className="copy">Copy Code</button>
  </CopyToClipboard>
  */}
        </div>
      </div>
      {/* 
{copied ? <p className="copied-message">Code copied to clipboard!</p> : null} 
*/}
    
    </div>
    );
  }
}
