import React, { Component } from 'react'; 
import Panel from 'react-bootstrap/lib/Panel';
import Button from 'react-bootstrap/lib/Button';
import CustomerDetails from './CustomerDetails';
import axios from 'axios';
import { FaJava, FaPython, FaJs, FaPhp  } from 'react-icons/fa';
import JavaEbookAd from './JavaEbookAd';
import { SiCplusplus } from 'react-icons/si';
import './Customers.css';

export default class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCustomer: 1,
      customerList: null,
    };
    this.clearCodeEditor = null;
    this.customerDetailsRef = React.createRef(); // Create a ref for CustomerDetails container
  }

  componentDidMount() {
    this.getCustomerData();
  }

  getCustomerData() {
    axios.get('assets/samplejson/Home.json').then(response => {
      this.setState({ Home: response.data });  
    });
  }

  handleCustomerSelection = (customerId) => {
    this.setState({ selectedCustomer: customerId });
    if (this.clearCodeEditor) {
      this.clearCodeEditor();
    }
    // Scroll to CustomerDetails container
    if (this.customerDetailsRef.current) {
      this.customerDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  renderIcon(name) {
    const iconProps = { className: "icon", size: "2em" };

    switch (name.toLowerCase()) {
      case 'java':
        return <FaJava {...iconProps} />;
      case 'python':
        return <FaPython {...iconProps} />;
      case 'javascript':
        return <FaJs {...iconProps} />;
      case 'php':
        return <FaPhp {...iconProps} />;
      case 'c++':
        return <SiCplusplus {...iconProps} />;
      default:
        return null;
    }
  }

  render() {
    if (!this.state.Home) return <p>Loading data</p>;

    return (
      <div className="addmargin">
          <h1>Solve Challenges</h1>

        <div className="horizontal-panels">
          {this.state.Home.map(customer => (
            <Panel bsStyle="custom" key={customer.name} className="custom-panel">
              <Panel.Heading>
                <Panel.Title componentClass="h3" className="panel-title">
                  {customer.name}
                  <div className="icon-container">
                    {this.renderIcon(customer.name)}
                  </div>
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <p>{customer.email}</p>
                <Button
                  bsStyle="custom"
                  className="custom-button"
                  onClick={() => this.handleCustomerSelection(customer.id)}
                >
                  Solve Daily Challenge
                </Button>
              </Panel.Body>
            </Panel>
          ))}
        </div>
        <div className="customerdetails-container" ref={this.customerDetailsRef}>
          <CustomerDetails
            val={this.state.selectedCustomer}
            setClearCodeEditor={(fn) => { this.clearCodeEditor = fn; }}
          />
          <JavaEbookAd />
        </div>
      </div>
    );
  }
}
