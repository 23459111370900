import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import './HeaderPages.css';
import { FaEnvelope, FaLock, FaUser,FaHome } from 'react-icons/fa';
import { FaJava, FaPython, FaPhp, FaJsSquare } from 'react-icons/fa';
import { SiCplusplus } from 'react-icons/si';
import logo from '../src/crunch logo.png';
import { auth, googleProvider } from './firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, onAuthStateChanged } from 'firebase/auth';


const HeaderPages = ({ location }) => {
  
  const [isScrolled, setIsScrolled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState('signIn');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const openModal = (tab) => {
    setActiveTab(tab);
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  const handleSignIn = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("Signed in:", userCredential.user);
        closeModal();
      })
      .catch((error) => {
        console.error("Error signing in:", error);
      });
  };

  const handleSignUp = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("Registered:", userCredential.user);
        closeModal();
      })
      .catch((error) => {
        console.error("Error registering:", error);
      });
  };

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        console.log("Signed in with Google:", result.user);
        closeModal();
      })
      .catch((error) => {
        console.error("Error signing in with Google:", error);
      });
  };

  const handleSignOut = () => {
    auth.signOut().then(() => {
      setCurrentUser(null);
    }).catch((error) => {
      console.error("Error signing out:", error);
    });
  };

  const getInitials = (email) => {
    const [username] = email.split('@');
    return username.slice(0, 2).toUpperCase();
  };

  const toggleDropdown = (dropdown) => {
    if (dropdownOpen === dropdown) {
      setDropdownOpen(null);
    } else {
      setDropdownOpen(dropdown);
    }
  };

  const closeDropdown = () => {
    setDropdownOpen(null);
  };

  return (
    <React.Fragment>
      <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
        <div className="header-left">
          <img src={logo} alt="Logo" className="logo" />
        </div>
       
        <nav className="header-nav">
          <ul>
          

<li
  className="dropdown"
  onMouseOver={() => setDropdownOpen('guide')}
  onMouseLeave={closeDropdown}
  onClick={() => toggleDropdown('guide')}
>
  <Link to="#" onClick={(e) => e.preventDefault()}>Programming Guide</Link>
  {dropdownOpen === 'guide' && (
    <div className="dropdown-content">
      <Link to="/java-guide" onClick={closeDropdown}><FaJava /> Java Guide</Link>
      <Link to="/python-guide" onClick={closeDropdown}><FaPython /> Python Guide</Link>
      <Link to="/javascript-guide" onClick={closeDropdown}><FaJsSquare /> JavaScript Guide</Link>
      <Link to="/cpp-guide" onClick={closeDropdown}><SiCplusplus /> C++ Guide</Link>
      <Link to="/php-guide" onClick={closeDropdown}><FaPhp /> PHP Guide</Link>
    </div>
  )}
</li>

<li
  className="dropdown"
  onMouseOver={() => setDropdownOpen('projects')}
  onMouseLeave={closeDropdown}
  onClick={() => toggleDropdown('projects')}
>
  <Link to="#" onClick={(e) => e.preventDefault()}>Build Projects</Link>
  {dropdownOpen === 'projects' && (
    <div className="dropdown-content">
      <Link to="/java-projects" onClick={closeDropdown}><FaJava /> Java Projects</Link>
      <Link to="/python-projects" onClick={closeDropdown}><FaPython /> Python Projects</Link>
      <Link to="/javascript-projects" onClick={closeDropdown}><FaJsSquare /> JavaScript Projects</Link>
      <Link to="/cpp-projects" onClick={closeDropdown}><SiCplusplus /> C++ Projects</Link>
      <Link to="/php-projects" onClick={closeDropdown}><FaPhp /> PHP Projects</Link>
    </div>
  )}
</li>

<li
  className="dropdown"
  onMouseOver={() => setDropdownOpen('interview')}
  onMouseLeave={closeDropdown}
  onClick={() => toggleDropdown('interview')}
>
  <Link to="#" onClick={(e) => e.preventDefault()}>Interview Questions</Link>
  {dropdownOpen === 'interview' && (
    <div className="dropdown-content">
      <Link to="/java-interview" onClick={closeDropdown}><FaJava /> Java Interview</Link>
      <Link to="/python-interview" onClick={closeDropdown}><FaPython /> Python Interview</Link>
      <Link to="/javascript-interview" onClick={closeDropdown}><FaJsSquare /> JavaScript Interview</Link>
      <Link to="/cpp-interview" onClick={closeDropdown}><SiCplusplus /> C++ Interview</Link>
      <Link to="/php-interview" onClick={closeDropdown}><FaPhp /> PHP Interview</Link>
    </div>
  )}
</li>

          </ul>
        </nav>
        <div className="header-right">
        {location.pathname !== '/Home' && (
          <Link to="/Home" className="header-button home-button">
            <FaHome /> Home
          </Link>
        )}

          {currentUser ? (
            <div className="user-info">
              <div className="user-initials">{getInitials(currentUser.email)}</div>
              <button onClick={handleSignOut} className="header-button">Sign Out</button>
            </div>
          ) : (
            <>
              <button onClick={() => openModal('signIn')} className="header-button">Sign In</button>
              <button onClick={() => openModal('signUp')} className="header-button">Register</button>
            </>
          )}
        </div>
      </header>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={closeModal}>X</button>
            <div className="tabs">
              <div
                className={`tab ${activeTab === 'signIn' ? 'tab-active' : ''}`}
                onClick={() => setActiveTab('signIn')}
              >
                Sign In
              </div>
              <div
                className={`tab ${activeTab === 'signUp' ? 'tab-active' : ''}`}
                onClick={() => setActiveTab('signUp')}
              >
                Register
              </div>
            </div>

            {activeTab === 'signIn' && (
              <div>
                <div className="form-group">
                  <label>Email:</label>
                  <div className="input-icon">
                    <FaEnvelope className="icon" />
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your email address" />
                  </div>
                </div>
                <div className="form-group">
                  <label>Password:</label>
                  <div className="input-icon">
                    <FaLock className="icon" />
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Your password" />
                  </div>
                </div>
                <button className="submit-button" onClick={handleSignIn}>Sign In</button>
                <div className="or-divider">
                  <span>OR</span>
                </div>
                <div className="social-buttons">
                  <button className="social-button google" onClick={handleGoogleSignIn}>Sign in with Google</button>
                </div>
              </div>
            )}

            {activeTab === 'signUp' && (
              <div>
                <div className="form-group">
                  <label>Username:</label>
                  <div className="input-icon">
                    <FaUser className="icon" />
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Pick a username" />
                  </div>
                </div>
                <div className="form-group">
                  <label>Email:</label>
                  <div className="input-icon">
                    <FaEnvelope className="icon" />
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your email address" />
                  </div>
                </div>
                <div className="form-group">
                  <label>Password:</label>
                  <div className="input-icon">
                    <FaLock className="icon" />
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Your password" />
                  </div>
                </div>
                <button className="submit-button" onClick={handleSignUp}>Register</button>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default withRouter(HeaderPages);
