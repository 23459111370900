import React from 'react'; 
import './JavaEbookAd.css'; // Ensure this CSS file exists
import { FaEye, FaAmazon } from 'react-icons/fa'; // Importing icons for preview and buy buttons
import { GiBookmarklet } from 'react-icons/gi'; // Importing icon for book/ebook
import ebookImage from './ebookimg.png'; // Adjust the path as necessary

const JavaEbookAd = () => {
  const handleWatchPreview = () => {
    window.open('https://www.amazon.com/JAVA-CHRONICLES-Expertise-Through-Challenges-ebook/dp/B0D6G7S5DX');
  };

  const handleBuyNow = () => {
    window.open('https://www.amazon.com/JAVA-CHRONICLES-Expertise-Through-Challenges-ebook/dp/B0D6G7S5DX');
  };

  return (
    <div className="java-ebook-ad">
      <div className="ebook-image">
        <img src={ebookImage} alt="Java Ebook" />
        <p className="image-caption">
          <GiBookmarklet style={{ color: '#FF9900', marginRight: '5px' }} /> 
          Java Chronicles Ebook: 500 Challenges for you to practice ranging from beginner to expert. 📚
        </p>
      </div>
      <div className="ebook-content">
        <div className="ebook-details">
          <h2>JAVA-CHRONICLES: 500 Challenges ⚔️</h2> {/* Added a challenge icon */}
          <p>Enhance your Java skills with our comprehensive ebook featuring 500 challenges designed to take you from beginner to expert. 🚀</p>
        </div>
        <div className="ebook-actions">
          <button className="preview-button" onClick={handleWatchPreview}>
            <FaEye style={{ marginRight: '8px' }} /> Watch Preview
          </button>
          <button className="buy-button" onClick={handleBuyNow}>
            <FaAmazon style={{ marginRight: '8px' }} /> Buy Now from Amazon
          </button>
        </div>
      </div>
    </div>
  );
};

export default JavaEbookAd;
