import React, { useState } from "react";
import data from '../projects-json/JavaScriptProjects.json';
import '../projects-css/JavaScriptProjects.css';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrowNightBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { FaJsSquare, FaListAlt, FaBars } from 'react-icons/fa';

const JavaScriptProjects = () => {
  const [selectedSubtopic, setSelectedSubtopic] = useState(data.topics[0].subtopics[0].title);
  const [sidebarOpen, setSidebarOpen] = useState(false); // State to manage sidebar visibility

  const handleSubtopicClick = (subtopic) => {
    setSelectedSubtopic(subtopic.title);
    setSidebarOpen(false); // Close sidebar after selecting a subtopic on smaller devices
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const renderSteps = () => {
    const subtopic = data.topics.flatMap(topic =>
      topic.subtopics.flatMap(subtopic => subtopic.subtopics || subtopic)
    ).find(subtopic => subtopic.title === selectedSubtopic);

    if (!subtopic) return null;

    return subtopic.steps.map((step, stepIndex) => (
      <div key={stepIndex} className="step-jsp">
        <h3 className="step-subject-jsp">{step.subject}</h3>
        <h4 className="step-overview-jsp">{step.overview}</h4>
        <div className="step-description-jsp">
          {step.description.split('\n').map((line, index) => (
            <h5 key={index}>{line}</h5>
          ))}
        </div>
        {step.program && (
          <SyntaxHighlighter language="javascript" style={tomorrowNightBlue} className="code-block">
            {step.program}
          </SyntaxHighlighter>
        )}
      </div>
    ));
  };

  return (
    <div className="javascript-project"> {/* Use the same className for consistent layout */}
      <button className="sidebar-toggle-jsp" onClick={toggleSidebar}>
        <FaBars /> JavaScript Projects
      </button>
      <div className={`sidebar-jsp ${sidebarOpen ? 'open' : ''}`}>
        {data.topics.map((topic, index) => (
          <div key={index}>
            <h2 className="topic-title-jsp"><FaListAlt /> {topic.title}</h2>
            <ul>
              {(topic.subtopics || []).map((subtopic, subIndex) => (
                <React.Fragment key={subIndex}>
                  <li onClick={() => handleSubtopicClick(subtopic)} className="subtopic-title-jsp">
                    <FaJsSquare /> {subtopic.title}
                  </li>
                  {(subtopic.subtopics || []).map((nestedSubtopic, nestedIndex) => (
                    <li
                      key={`${subIndex}-${nestedIndex}`}
                      onClick={() => handleSubtopicClick(nestedSubtopic)}
                      className="subtopic-title nested-subtopic-jsp"
                    >
                      <FaJsSquare /> {nestedSubtopic.title}
                    </li>
                  ))}
                </React.Fragment>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="content-jsp">
        {selectedSubtopic && (
          <React.Fragment>
            <h1 className="selected-subtopic-title-jsp"><FaJsSquare /> {selectedSubtopic}</h1>
            <div className="steps-jsp">
              {renderSteps()}
            </div>
          </React.Fragment>
        )}
      </div>

      <div className="ads-sidebar-jsp">
        {/* Place your Google AdSense code here */}
        
      </div>
    </div>
  );
};

export default JavaScriptProjects;
